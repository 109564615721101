@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

body {
  font-family: 'DM Sans', sans-serif;
}

.removescroll {
  overflow-y: hidden !important;
}

body.chakra-ui-light {
  background: #FFF2E4;
}

option {
  color: black;
}

.chakra-form__label.css-186ooiv {
  margin-top: 10px !important;
}

.text-right {
  text-align: end !important;
  color: black !important;
}

.highlighted {
  background-color: yellow;
}

.opaCls {
  opacity: 1;
}

.checkcls {
  opacity: 0.5;
}

.error {
  color: tomato !important;
}

.hidden {
  display: none !important;
}

.uploadtext {
  margin-left: 10px;
}

.sideHeader {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
  color: #FF8700;
  padding: 12px 0px;
}

.formHeading {
  font-size: 24px;
  font-weight: 600;
  color: #FF8700;
  text-align: center;
  padding: 12px 0px;
}

.footerHeading {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.fw-500 {
  font-weight: 500;
}

.note {
  font-size: 18px;
  font-weight: 500;
}

.badge {
  position: absolute;
  right: 85px;
}

.formLabel {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  margin-top: 12px;
}

.signUp {
  color: #ffe663;
  font-size: 14px;
  font-weight: 500;
}

.insClass {
  margin-right: 40px;
  margin-left: 10%;
}

.menuItem {
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  margin: 10px 0px;
}

.menuList {
  padding: 21px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25) !important;
}

.status {
  display: flex;
  align-items: center;
  margin: 24px;
}

.header {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 0px !important;
}

.additionaldetailsheader {
  display: flex;
  justify-content: space-between;
  padding: 18px 0px 0px 0px;
}

.additionaldetails {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}

.background {
  background: #ececec;
  color: #4c4c4c;
  font-size: 12px;
  width: 14vh;
  border-radius: 5px;
  padding: 7px 0px;
}

.listheader {
  background: #fff !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 8px;
}

.subjectsedit {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.subjectslisteditview {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #c6c6c6;
}

.subjectslistedit {
  display: flex;
  justify-content: center;
}

.subjectformbtns {
  display: flex;
  justify-content: space-between;
}

.logingrid {
  width: 100%;
  height: 90vh;
}

.firsthalf {
  width: 50%;
  height: 100vh;
  background: #EC9737;
}

.secondhalf {
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondhalfimage {
  width: 70%;
}

.branchsettingslabelheader {
  color: #FF8700;
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
}

.branchsettingsheader {
  color: #FF8700;
  font-size: 24px;
  font-weight: 500;
}

.branchsettingsdata {
  color: #FF8700;
  font-size: 16px;
  font-weight: 400;
}

.viewHeadings {
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  color: #FF8700;
}

.viewValue {
  font-weight: 500;
  color: #FF8700;
  font-size: 16px;
}

.viewLabel {
  font-weight: 400;
  color: #FF8700;
  font-size: 16px;
}

.drawerbodyclass {
  display: grid;
}

.drawerformclass {
  display: grid;
  align-content: space-between;
}

.gridClass {
  position: relative;
  left: -45px;
}

.filesclass {
  display: flex;
}

.formerror_msg {
  margin-left: 12px;
  margin-top: 0px !important;
  color: #FF6347 !important;
  font-size: 16px !important;
}

.labelStyle {
  width: 120px;
}

.label {
  margin-bottom: 0px !important;
  color: #FF8700 !important;
  font-size: 14px !important;
}

.note {
  font-size: 12px;
  color: #FF8700 !important;
}

.dashboardCard {
  align-items: center;
  display: flex;
  justify-content: center;
  background: rgba(115, 87, 246, 0.08);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.dashBoardtxt {
  display: flex;
  justify-content: center;
}